import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {Store} from '../../lib/StoreType';
import {KomodityList, VyjadrovackaInputCustomData} from '../types';
import {ValidationError} from '../utils/CommonTypes';

export type CommonStore = {
    readonly activeStep: number;
    readonly formData: Partial<VyjadrovackaInputCustomData> | undefined;
    readonly formSuccessSteps: {[key in number]: boolean} | undefined;
    readonly endOfForm: boolean;
    readonly errorLoginConfig?: boolean | undefined;
    readonly loginConfigDisabled?: boolean | undefined;
    readonly errorLogin?: ValidationError | undefined;
    readonly loginSuccess?: boolean;
    readonly isFetchingLogin?: boolean;
    readonly logouting?: boolean;
    readonly isFetchingLoginConfig?: boolean;
    readonly geoPortlalKomodity?: KomodityList;
    readonly selfcareLogin?: boolean;
};

const initialState: CommonStore = {
    activeStep: 0,
    formData: undefined,
    formSuccessSteps: undefined,
    endOfForm: false,
    errorLoginConfig: undefined,
    errorLogin: undefined,
    loginSuccess: false,
    isFetchingLoginConfig: false,
    logouting: false,
    loginConfigDisabled: undefined,
    isFetchingLogin: false,
    geoPortlalKomodity: undefined,
    selfcareLogin: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSelfcareLogin: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => ({...state, selfcareLogin: payload}),
        setFormData: (state: CommonStore, {payload}: PayloadAction<Partial<VyjadrovackaInputCustomData>>): CommonStore => {
            return {...state, formData: {...state.formData, ...payload}};
        },
        setkomodityList: (state: CommonStore, {payload}: PayloadAction<KomodityList>): CommonStore => {
            return {...state, geoPortlalKomodity: {...state.geoPortlalKomodity, ...payload}};
        },
        setActiveStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, activeStep: payload};
        },
        setSuccessStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, formSuccessSteps: {...state.formSuccessSteps, [payload]: true}};
        },
        setEndForm: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, endOfForm: payload};
        },
        fetchingLogin: (state: CommonStore): CommonStore => ({...state, isFetchingLogin: true}),
        fetchedLogin: (state: CommonStore): CommonStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: undefined,
            loginSuccess: true,
        }),

        errorfetchedLogin: (state: CommonStore, {payload}: PayloadAction<ValidationError>): CommonStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: payload,
        }),
        errorfetchedLoginConfig: (state: CommonStore, {payload}: PayloadAction<ValidationError>): CommonStore => ({
            ...state,
            isFetchingLoginConfig: false,
            errorLogin: payload,
        }),

        logouting: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => ({
            ...state,
            logouting: payload,
            loginSuccess: false,
        }),

        loginConfigFetching: (state: CommonStore): CommonStore => {
            return {...state, isFetchingLoginConfig: true};
        },

        loginConfigFetched: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, isFetchingLoginConfig: false, loginConfigDisabled: payload};
        },
    },
});

export const {
    setActiveStep,
    setFormData,
    setSuccessStep,
    setEndForm,
    errorfetchedLogin,
    errorfetchedLoginConfig,
    fetchedLogin,
    fetchingLogin,
    loginConfigFetched,
    loginConfigFetching,
    logouting,
    setkomodityList,
    setSelfcareLogin,
} = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: Store) => state.common;
export const selectCommon = createSelector([common], (common) => common);

export default commonSlice.reducer;
