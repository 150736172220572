import {PaperProps, Typography} from '@mui/material';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {useMatches} from '../../utils/CommonUtils';

type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element | undefined;
    readonly description?: string | JSX.Element;
    readonly titleBlockIcon?: JSX.Element | string;
    readonly titleRightBlock?: JSX.Element | string;
    readonly centerTitle?: boolean;
    readonly disabledChildrenMargin?: boolean;
};

export const PaperBlock: FC<PaperBlockProps> = ({children, titleBlock, titleBlockIcon, description, centerTitle, titleRightBlock, disabledChildrenMargin}) => {
    const matches = useMatches();

    return (
        <Div sx={{backgroundColor: 'background.default', position: 'relative'}}>
            <Div
                sx={{
                    ...(centerTitle
                        ? {
                              display: 'contents',
                              textAlign: 'center',
                          }
                        : {
                              display: 'flex',
                              alignItems: 'center',
                              height: 60,
                          }),
                }}
            >
                {titleBlockIcon && (
                    <Div
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {titleBlockIcon}
                    </Div>
                )}

                {titleBlock && (
                    <Typography
                        sx={{
                            marginLeft: 2,
                            fontWeight: 'bold',
                        }}
                        variant={matches ? 'h5' : 'h4'}
                    >
                        {typeof titleBlock === 'string' ? <FormattedMessage id={titleBlock} /> : titleBlock}
                    </Typography>
                )}
                {titleRightBlock && (
                    <Div
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        {titleRightBlock}
                    </Div>
                )}
            </Div>
            <Div sx={{marginLeft: 7, marginBottom: 2}}>
                {description && (
                    <Typography variant="subtitle1">{typeof description === 'string' ? <FormattedMessage id={description} /> : description}</Typography>
                )}
            </Div>

            <Div
                sx={(theme) => ({
                    margin: disabledChildrenMargin
                        ? theme.spacing(matches ? 3 : 10, matches ? 0 : 7, matches ? 3 : 10, matches ? 0 : 2)
                        : theme.spacing(matches ? 3 : 10, matches ? 0 : 7, matches ? 3 : 10, matches ? 0 : 7),
                })}
            >
                {children}
            </Div>
        </Div>
    );
};
