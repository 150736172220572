import {
    Maybe,
    VyjadrovackaInvestor,
    VyjadrovackaUzivatelskyProfil,
    VyjadrovackaUzivatelskyProfilAdresa,
    VyjadrovackaZadatel,
} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';

export const Zahranici = {
    ANO: 'ANO',
    NE: 'NE',
} as const;

export type Zahranici = (typeof Zahranici)[keyof typeof Zahranici];

export type VyjadrovackaData = {
    readonly zadatel: Maybe<VyjadrovackaUzivatelskyProfil & {zahranici: Zahranici}> | undefined;
};

export type KomodityList = {
    ELEKTRINA?: boolean;
    PLYN?: boolean;
};

export type VyjadrovackaTechnickaSpecifikaceCustom = {
    elektrina: 'ELEKTRINA' | undefined;
    plyn: 'PLYN' | undefined;
    digitalniDokumentace: boolean;
    udajeOStavbe: string;
    poznamka?: string;
};

export type VyjadrovackaZadatelCustom = Omit<VyjadrovackaZadatel, 'adresa'> & {
    zahranici: Zahranici;
    adresa: VyjadrovackaUzivatelskyProfilAdresa | undefined;
};

export type VyjadrovackaInputCustomData = {
    id?: string;
    investor: VyjadrovackaInvestor & {zahranici: Zahranici};
    technickaSpecifikace: VyjadrovackaTechnickaSpecifikaceCustom;
    tid?: string;
    zadatel: VyjadrovackaZadatelCustom;
    zadatelJeInvestor: boolean;
    saveOsobniUdaje?: boolean;
};
