import {WarningOutlined} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {redirect} from '../../../auth/service/LoginService';
import {PageRoute} from '../../constants';
import {Div} from '../../styledComponents/Div';
import {PaperBlock} from './PaperBlock';

type Props = {
    readonly message: string | undefined;
    readonly refetch: () => void;
};

/**
 * Komponenta pro zobrazení informace při vypnutí formuláře z admin portálu
 *
 * @param {*} {message, refetch}
 */
export const FormOff = ({message, refetch}: Props) => {
    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => redirect({pathname: PageRoute.VSTUP});
    const handleRefresh = () => refetch();

    return (
        <Div
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <WarningOutlined color="error" sx={{fontSize: 120, marginRight: 10}} />
            <Div
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 10,
                }}
            >
                <PaperBlock titleBlock={<FormattedMessage id="form.off" />} elevation={8} disabledChildrenMargin>
                    <Typography
                        variant="body1"
                        color="inherit"
                        dangerouslySetInnerHTML={{
                            __html:
                                message ?? '<p>Omlouváme se, ale z technických důvodů jsme museli formulář dočasně odstavit a pracujeme na rychlé opravě.</p>',
                        }}
                    />

                    <Div
                        sx={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Button
                            size="medium"
                            color="primary"
                            sx={{
                                marginRight: 10,
                            }}
                            variant="contained"
                            disableRipple
                            onClick={handleRootRedirect}
                        >
                            <FormattedMessage id="button.root" />
                        </Button>
                        <Button size="medium" color="primary" variant="contained" disableRipple onClick={handleRefresh}>
                            <FormattedMessage id="button.aktualizovat" />
                        </Button>
                    </Div>
                </PaperBlock>
            </Div>
        </Div>
    );
};
